"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const appConfig = {
    theme: {
        dark: true,
    },
    appName: 'DiiCa',
    organizationId: 'mP6k1FdSNmmEVNbMJVaZ',
    version: '1.3',
    ionicAppId: '10b68c78',
    applicationKey: 'diica',
    applicationType: 'organization',
    artistId: undefined,
    languages: ['ja', 'en'],
    ios: {
        bundleId: 'jp.diica',
        appId: '1597719315',
    },
    android: {
        bundleId: 'jp.diica',
    },
    platform: ['native'],
    custom: {
        modules: {
            tutorial: true,
            trade: true,
            membership: false,
            thread: true,
            prepaidPoint: {
                chargeable: true,
            },
            gacha: true,
            nft: false,
            liveStream: true,
            item: true,
            store: false,
            campaign: true,
            mission: true,
            resale: false,
            preSale: false,
            event: false,
            cart: false,
            pickup: false,
            eprint: true,
            konbiniPayment: true,
        },
        tutorials: [
            'images/tutorial/Tutorial01.jpg',
            'images/tutorial/Tutorial02.jpg',
            'images/tutorial/Tutorial03.jpg',
        ],
        globalTabs: ['home', 'notifications', 'myProfile'],
        artistTabs: ['home', 'collections', 'gachas', 'trade', 'threads'],
    },
    deeplinkUrl: 'https://app.diica.jp',
    applink: 'app.diica.jp',
    helpUrl: 'https://help.utoniq.com/ja/collections/3305438',
    storagePath: 'diica',
    dynamicLinkPrefix: 'diica',
    deeplinkStg: 'diica-stg.netlify.app',
    deeplinkProd: 'app.diica.jp',
};
exports.default = appConfig;
